<template>
	<div class="page-web page-web-line page-bg sceond">
		<Head />
		<div class="ustandard-section">
			<div class="ustandard-body2">
				<div class="ustandard-row2">
					<div class="ustandard-col5 ustandard-favor">
						<div class="popper2-content ">
							<div class="popper2-search">
								<el-input :placeholder="$t('mplaceholder')" suffix-icon="el-icon-search"
									v-model.trim="keyword" @input="search"></el-input>
							</div>
							<div class="popper2-tab second-tab">
								<el-tabs v-model="activeName4">
									<el-tab-pane v-if="isLogin == true" :label="$t('mTab')" name="first">
										<div class="popper2-table second-table" :class="{right:findData.length<8}">
											<el-table :data="findData" style="width: 100%" height="320"
												@row-click="rowClick">
												<el-table-column prop="isFavor" width="40" align="center">
													<template slot-scope="scope">
														<el-button type="text" class="favor-btn"
															@click.stop="delFavor(scope.row,scope.$index)">
															<span class="iconfont icon-star"></span>
														</el-button>
														<!-- <el-button @click.stop="addFavor(scope.row)" type="text"
															class="favor-btn" v-else>
															<span class="iconfont icon-star_off"></span>
														</el-button> -->
													</template>
												</el-table-column>
												<el-table-column prop="symbol" :label="$t('czTxt2')" width="140"
													align="center">
													<template slot-scope="scope">
														<span>{{scope.row.symbol}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="close" sortable :label="$t('mth1')">
												</el-table-column>
												<el-table-column prop="chg" sortable :label="$t('mth2')" align="right">
													<template slot-scope="scope">
														<span class="text-green"
															v-if="scope.row.chg > 0">+{{scope.row.chg}}%</span>
														<span class="text-green"
															v-if="scope.row.chg == 0">{{scope.row.chg}}%</span>
														<span class="text-red"
															v-if="scope.row.chg < 0">{{ scope.row.chg}}%</span>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-tab-pane>
									<el-tab-pane label="USDT" name="second">
										<div class="popper2-table second-table" :class="{right:thumb.data1.length<8}">
											<el-table :data="thumb.data1" style="width: 100%" height="320"
												@row-click="rowClick">
												<el-table-column prop="isFavor" width="40" align="center">
													<template slot-scope="scope">
														<el-button type="text" class="favor-btn"
															@click.stop="delFavor(scope.row,scope.$index)"
															v-if="scope.row.isFavor">
															<span class="iconfont icon-star"></span>
														</el-button>
														<el-button v-else @click.stop="addFavor(scope.row)" type="text"
															class="favor-btn">
															<span class="iconfont icon-star_off"></span>
														</el-button>
													</template>
												</el-table-column>
												<el-table-column prop="symbol" :label="$t('czTxt2')" width="140"
													align="center">
													<template slot-scope="scope">
														<span>{{scope.row.symbol}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="close" sortable :label="$t('mth1')">
												</el-table-column>
												<el-table-column prop="chg" sortable :label="$t('mth2')" align="right">
													<template slot-scope="scope">
														<span class="text-green"
															v-if="scope.row.chg > 0">+{{scope.row.chg}}%</span>
														<span class="text-green"
															v-if="scope.row.chg == 0">{{scope.row.chg}}%</span>
														<span class="text-red"
															v-if="scope.row.chg < 0">{{ scope.row.chg}}%</span>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-tab-pane>
									<el-tab-pane label="BTC" name="third">
										<div class="popper2-table  second-table" :class="{right:thumb.data2.length<8}">
											<el-table :data="thumb.data2" style="width: 100%" height="320"
												@row-click="rowClick">
												<el-table-column prop="isFavor" width="40" align="center">
													<template slot-scope="scope">
														<el-button type="text" class="favor-btn"
															@click.stop="delFavor(scope.row,scope.$index)"
															v-if="scope.row.isFavor">
															<span class="iconfont icon-star"></span>
														</el-button>
														<el-button @click.stop="addFavor(scope.row)" type="text"
															class="favor-btn" v-else>
															<span class="iconfont icon-star_off"></span>
														</el-button>
													</template>
												</el-table-column>
												<el-table-column prop="symbol" :label="$t('czTxt2')" width="140"
													align="center">
													<template slot-scope="scope">
														<span>{{scope.row.symbol}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="close" sortable :label="$t('mth1')">
												</el-table-column>
												<el-table-column prop="chg" sortable :label="$t('mth2')" align="right">
													<template slot-scope="scope">
														<span class="text-green"
															v-if="scope.row.chg > 0">+{{scope.row.chg}}%</span>
														<span class="text-green"
															v-if="scope.row.chg == 0">{{scope.row.chg}}%</span>
														<span class="text-red"
															v-if="scope.row.chg < 0">{{ scope.row.chg}}%</span>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-tab-pane>
									<el-tab-pane label="ETH" name="fourth">
										<div class="popper2-table  second-table" :class="{right:thumb.data3.length<8}">
											<el-table :data="thumb.data3" style="width: 100%" height="320"
												@row-click="rowClick">
												<el-table-column prop="isFavor" width="40" align="center">
													<template slot-scope="scope">
														<el-button type="text" class="favor-btn"
															@click.stop="delFavor(scope.row,scope.$index)"
															v-if="scope.row.isFavor">
															<span class="iconfont icon-star"></span>
														</el-button>
														<el-button @click.stop="addFavor(scope.row)" type="text"
															class="favor-btn" v-else>
															<span class="iconfont icon-star_off"></span>
														</el-button>
													</template>
												</el-table-column>
												<el-table-column prop="symbol" :label="$t('czTxt2')" width="140"
													align="center">
													<template slot-scope="scope">
														<span>{{scope.row.symbol}}</span>
													</template>
												</el-table-column>
												<el-table-column prop="close" sortable :label="$t('mth1')">
												</el-table-column>
												<el-table-column prop="chg" sortable :label="$t('mth2')" align="right">
													<template slot-scope="scope">
														<span class="text-green"
															v-if="scope.row.chg > 0">+{{scope.row.chg}}%</span>
														<span class="text-green"
															v-if="scope.row.chg == 0">{{scope.row.chg}}%</span>
														<span class="text-red"
															v-if="scope.row.chg < 0">{{ scope.row.chg}}%</span>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-tab-pane>
								</el-tabs>
							</div>
						</div>
						<div class="ustandard-table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="price" :label="$t('wtth3')+'(USDT)'" align="center">
									<template slot-scope="scope">
										<span v-if="scope.row.direction == 'BUY'"
											class="text-green">{{ scope.row.price }}</span>
										<span v-if="scope.row.direction == 'SELL'"
											class="text-red">{{ scope.row.price }}</span>
									</template>
								</el-table-column>
								<el-table-column prop="amount" :label="$t('wtth4')+'(BTC)'" align="center">
									<template slot-scope="scope">
										<span>{{ scope.row.amount.toFixed(6) }}</span>
									</template>
								</el-table-column>
								<el-table-column prop="time" :label="$t('wth1')" align="right">
									<template slot-scope="scope">
										<span>{{ scope.row.time | datefmt('HH:mm:ss')}}</span>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<div class="ustandard-col1 ustandard-border flex_bd">
						<div class="contract-info contract-second-info">
							<div class="contract-info__hd">
								<div class="contract-bi">
									{{ currentCoin.coin }} <span class="contract-base">/{{ currentCoin.base }}</span>
								</div>
								<el-tooltip placement="bottom-start">
									<div slot="content" class="custom-tip">
										<div>
											{{coinInfo.information}}
										</div>
										<div class="custom-tip-foot">
											<a :href="coinInfo.infolink" target="_blank">{{$t('labDetail')}}</a>
										</div>
									</div>
									<el-button type="text"><i class="el-icon-warning"></i></el-button>
								</el-tooltip>
								<!-- 移动端 -->
								<div class="contract-down">
									<el-popover placement="top-start" v-model="popover" popper-class="custom-popper2"
										width="425" trigger="click">
										<div class="popper2-content">
											<div class="popper2-search">
												<el-input :placeholder="$t('mplaceholder')" suffix-icon="el-icon-search"
													v-model.trim="keyword" @input="search"></el-input>
											</div>
											<div class="popper2-tab">
												<el-tabs v-model="activeName4">
													<el-tab-pane v-if="isLogin == true" :label="$t('mTab')"
														name="first">
														<div class="popper2-table second-table"
															:class="{right:findData.length<8}">
															<el-table :data="findData" style="width: 100%" height="320"
																@row-click="rowClick">
																<el-table-column prop="isFavor" width="30"
																	align="center">
																	<template slot-scope="scope">
																		<el-button type="text" class="favor-btn"
																			@click.stop="delFavor(scope.row,scope.$index)">
																			<span class="iconfont icon-star"></span>
																		</el-button>
																		<!-- <el-button @click.stop="addFavor(scope.row)" type="text"
																			class="favor-btn" v-else>
																			<span class="iconfont icon-star_off"></span>
																		</el-button> -->
																	</template>
																</el-table-column>
																<el-table-column prop="symbol" :label="$t('czTxt2')"
																	width="120" align="center">
																	<template slot-scope="scope">
																		<span>{{scope.row.symbol}}</span>
																	</template>
																</el-table-column>
																<el-table-column prop="close" sortable
																	:label="$t('mth1')">
																</el-table-column>
																<el-table-column prop="chg" sortable :label="$t('mth2')"
																	align="right">
																	<template slot-scope="scope">
																		<span class="text-green"
																			v-if="scope.row.chg > 0">+{{scope.row.chg}}%</span>
																		<span class="text-green"
																			v-if="scope.row.chg == 0">{{scope.row.chg}}%</span>
																		<span class="text-red"
																			v-if="scope.row.chg < 0">{{ scope.row.chg}}%</span>
																	</template>
																</el-table-column>
															</el-table>
														</div>
													</el-tab-pane>
													<el-tab-pane label="USDT" name="second">
														<div class="popper2-table second-table"
															:class="{right:thumb.data1.length<8}">
															<el-table :data="thumb.data1" style="width: 100%"
																height="320" @row-click="rowClick">
																<el-table-column prop="isFavor" width="30"
																	align="center">
																	<template slot-scope="scope">
																		<el-button type="text" class="favor-btn"
																			@click.stop="delFavor(scope.row,scope.$index)"
																			v-if="scope.row.isFavor">
																			<span class="iconfont icon-star"></span>
																		</el-button>
																		<el-button @click.stop="addFavor(scope.row)"
																			type="text" class="favor-btn" v-else>
																			<span class="iconfont icon-star_off"></span>
																		</el-button>
																	</template>
																</el-table-column>
																<el-table-column prop="symbol" :label="$t('czTxt2')"
																	width="120" align="center">
																	<template slot-scope="scope">
																		<span>{{scope.row.symbol}}</span>
																	</template>
																</el-table-column>
																<el-table-column prop="close" sortable
																	:label="$t('mth1')">
																</el-table-column>
																<el-table-column prop="chg" sortable :label="$t('mth2')"
																	align="right">
																	<template slot-scope="scope">
																		<span class="text-green"
																			v-if="scope.row.chg > 0">+{{scope.row.chg}}%</span>
																		<span class="text-green"
																			v-if="scope.row.chg == 0">{{scope.row.chg}}%</span>
																		<span class="text-red"
																			v-if="scope.row.chg < 0">{{ scope.row.chg}}%</span>
																	</template>
																</el-table-column>
															</el-table>
														</div>
													</el-tab-pane>
													<el-tab-pane label="BTC" name="third">
														<div class="popper2-table  second-table"
															:class="{right:thumb.data2.length<8}">
															<el-table :data="thumb.data2" style="width: 100%"
																height="320" @row-click="rowClick">
																<el-table-column prop="isFavor" width="30"
																	align="center">
																	<template slot-scope="scope">
																		<el-button type="text" class="favor-btn"
																			@click.stop="delFavor(scope.row,scope.$index)"
																			v-if="scope.row.isFavor">
																			<span class="iconfont icon-star"></span>
																		</el-button>
																		<el-button @click.stop="addFavor(scope.row)"
																			type="text" class="favor-btn" v-else>
																			<span class="iconfont icon-star_off"></span>
																		</el-button>
																	</template>
																</el-table-column>
																<el-table-column prop="symbol" :label="$t('czTxt2')"
																	width="120" align="center">
																	<template slot-scope="scope">
																		<span>{{scope.row.symbol}}</span>
																	</template>
																</el-table-column>
																<el-table-column prop="close" sortable
																	:label="$t('mth1')">
																</el-table-column>
																<el-table-column prop="chg" sortable :label="$t('mth2')"
																	align="right">
																	<template slot-scope="scope">
																		<span class="text-green"
																			v-if="scope.row.chg > 0">+{{scope.row.chg}}%</span>
																		<span class="text-green"
																			v-if="scope.row.chg == 0">{{scope.row.chg}}%</span>
																		<span class="text-red"
																			v-if="scope.row.chg < 0">{{ scope.row.chg}}%</span>
																	</template>
																</el-table-column>
															</el-table>
														</div>
													</el-tab-pane>
													<el-tab-pane label="ETH" name="fourth">
														<div class="popper2-table  second-table"
															:class="{right:thumb.data3.length<8}">
															<el-table :data="thumb.data3" style="width: 100%"
																height="320" @row-click="rowClick">
																<el-table-column prop="isFavor" width="30"
																	align="center">
																	<template slot-scope="scope">
																		<el-button type="text" class="favor-btn"
																			@click.stop="delFavor(scope.row,scope.$index)"
																			v-if="scope.row.isFavor">
																			<span class="iconfont icon-star"></span>
																		</el-button>
																		<el-button @click.stop="addFavor(scope.row)"
																			type="text" class="favor-btn" v-else>
																			<span class="iconfont icon-star_off"></span>
																		</el-button>
																	</template>
																</el-table-column>
																<el-table-column prop="symbol" :label="$t('czTxt2')"
																	width="120" align="center">
																	<template slot-scope="scope">
																		<span>{{scope.row.symbol}}</span>
																	</template>
																</el-table-column>
																<el-table-column prop="close" sortable
																	:label="$t('mth1')">
																</el-table-column>
																<el-table-column prop="chg" sortable :label="$t('mth2')"
																	align="right">
																	<template slot-scope="scope">
																		<span class="text-green"
																			v-if="scope.row.chg > 0">+{{scope.row.chg}}%</span>
																		<span class="text-green"
																			v-if="scope.row.chg == 0">{{scope.row.chg}}%</span>
																		<span class="text-red"
																			v-if="scope.row.chg < 0">{{ scope.row.chg}}%</span>
																	</template>
																</el-table-column>
															</el-table>
														</div>
													</el-tab-pane>
												</el-tabs>
											</div>
										</div>
										<el-button slot="reference" class="popper-btn">
											<div class="el-dropdown-select">
												<i class="el-icon-caret-bottom el-icon--right"></i>
											</div>
										</el-button>
									</el-popover>
								</div>
							</div>
							<div class="contract-info__bd flex_bd">
								<div class="contract-info-item">
									<div class="contract-info-item__label">{{$t('mth1')}}</div>
									<div class="contract-info-item__value">
										<span
											:class="{ buy: currentCoin.change > 0, sell: currentCoin.change < 0 }">{{ currentCoin.close | toFixed(baseCoinScale) }}</span><span
											class="text-info">≈ {{currentInfo.symbol}}
											{{ (currentCoin.usdRate * currentInfo.rate ) | toFixed(2) }}</span>
									</div>
								</div>
								<div class="contract-info-item">
									<div class="contract-info-item__label">{{$t('mth2')}}</div>
									<div class="contract-info-item__value"><span
											:class="{buy:currentCoin.change>0,sell:currentCoin.change<0}">{{currentCoin.rose}}</span>
									</div>
								</div>
								<div class="contract-info-item">
									<div class="contract-info-item__label">{{$t('mTxt')}}</div>
									<div class="contract-info-item__value">{{currentCoin.high | toFixed(baseCoinScale)}}
									</div>
								</div>
								<div class="contract-info-item">
									<div class="contract-info-item__label">{{$t('mTxt2')}}</div>
									<div class="contract-info-item__value">{{currentCoin.low | toFixed(baseCoinScale)}}
									</div>
								</div>
								<div class="contract-info-item">
									<div class="contract-info-item__label">{{$t('mTxt3')}}</div>
									<div class="contract-info-item__value">{{currentCoin.volume | toFixed(2)}}
										{{ currentCoin.coin }}
									</div>
								</div>
								<div class="contract-info-item">
									<div class="contract-info-item__label">{{$t('mTxt4')}}</div>
									<div class="contract-info-item__value">{{currentCoin.feePercent |  toFixed(2)}} %
									</div>
								</div>
							</div>
						</div>
						<div class="ustandard-tab">
							<div class="ustandard-tab-item" :class="{active:smCurrent == 1}" @click="smCurrent = 1">
								{{$t('chart')}}
							</div>
							<div class="ustandard-tab-item" :class="{active:smCurrent == 2}" @click="smCurrent = 2">
								{{$t('book')}}
							</div>
							<div class="ustandard-tab-item" :class="{active:smCurrent == 3}" @click="smCurrent = 3">
								{{$t('trade')}}
							</div>
						</div>
						<div class="u5-panel" :class="{show:smCurrent == 1}">
							<div class="u4-right handler">
								<el-button type="text" :class="current==1?'el-button--active':''"
									@click="currentClick(1)">
									{{$t('echat1')}}
								</el-button>
								<el-button type="text" :class="current==2?'el-button--active':''"
									@click="currentClick(2)">
									{{$t('echat2')}}
								</el-button>
							</div>
							<div v-show="current == 1">
								<!-- <div class="panel-head">
									<div class="contract-btns u5-left">
										<div class="u4-select">
											<el-radio-group v-model="radio1">
												<el-radio-button label="Time"></el-radio-button>
												<el-radio-button label="1">M1</el-radio-button>
												<el-radio-button label="5">M5</el-radio-button>
												<el-radio-button label="15">M15</el-radio-button>
												<el-radio-button label="60">H1</el-radio-button>
												<el-radio-button label="240">H4</el-radio-button>
												<el-radio-button label="1W">W1</el-radio-button>
											</el-radio-group>
										</div>
										<div class="u4-select">
											<el-popover placement="bottom" width="200" trigger="click">
												<el-button class="range-btn" slot="reference">{{resolution2}} <i
														class="el-icon-caret-bottom el-icon--right"></i></el-button>
												<div class="bi-radio bi-radio-sm">
													<el-radio-group v-model="resolution2">
														<el-radio-button label="M1">M1</el-radio-button>
														<el-radio-button label="M5">M5</el-radio-button>
														<el-radio-button label="M15">M15</el-radio-button>
														<el-radio-button label="M30">M30</el-radio-button>
														<el-radio-button label="H1">H1</el-radio-button>
														<el-radio-button label="H4">H4</el-radio-button>
														<el-radio-button label="D1">D1</el-radio-button>
														<el-radio-button label="1W">{{$t('option2')}}</el-radio-button>
														<el-radio-button label="1M">{{$t('option3')}}</el-radio-button>
													</el-radio-group>
												</div>
											</el-popover>
										</div>
										<el-button type="text" class="el-button--icon">
											<i class="iconfont icon-24gf-chartBarUpward"></i>
										</el-button>
										<el-button type="text" class="el-button--icon">
											<i class="iconfont icon-shezhi"></i>
										</el-button>
									</div>
									<div class="u4-right">
										<div class="u4-full">
											<el-button type="text"><i class="el-icon-full-screen"></i></el-button>
										</div>
									</div>
								</div> -->
								<div class="panel-body">
								<!-- 	// TODO:3 -->
								<div class="u4-box" style="padding-left:25px">
									<div class="u4-line" style="height:574px;" >
                    <TV :symbol="symbol" ref="tv" trade="second"></TV>
									</div>
								</div>
									<!-- <div class="u4-box">
										<div class="u4-head">
											<div class="u4-head-left">
												<div class="u4-item">
													{{$t('mTxt5')}}：<span class="text-green">15265.2554</span>
												</div>
												<div class="u4-item">
													{{$t('mTxt6')}}：<span class="text-green">19754.1400</span>
												</div>
												<div class="u4-item">
													{{$t('mTxt7')}}：<span class="text-green">19743.1500</span>
												</div>
												<div class="u4-item">
													{{$t('mTxt8')}}：<span class="text-green">19749.8000</span>
												</div>
											</div>
										</div>
										<div class="u4-btns">
											<el-button><i class="el-icon-arrow-right"></i></el-button>
										</div>
										<div class="echart-h1">
											<img src="../../assets/photo/web/u2.png" alt="">
										</div>
									</div> -->
								</div>
							</div>
							<div v-show="current == 2">
								<div class="depth-group flex">
									<div class="second-depth flex_bd" id="depth" v-if="current == 2"></div>
									<div class="second-depth flex_bd" id="depth2" v-if="current == 2"></div>
								</div>
							</div>
						</div>
						<!-- 移动端适应 -->
						<div class="mb-show2">
							<el-row>
								<el-col :sm="12">
									<div class="ustandard-table" :class="{show:smCurrent == 3}">
										<el-table :data="tableData" style="width: 100%">
											<el-table-column prop="price" :label="$t('wtth3')+'(USDT)'" align="center">
												<template slot-scope="scope">
													<span v-if="scope.row.direction == 'BUY'"
														class="text-green">{{ scope.row.price }}</span>
													<span v-if="scope.row.direction == 'SELL'"
														class="text-red">{{ scope.row.price }}</span>
												</template>
											</el-table-column>
											<el-table-column prop="amount" :label="$t('wtth4')+'(BTC)'" align="center">
											</el-table-column>
											<el-table-column prop="time" :label="$t('wth1')" align="right">
												<template slot-scope="scope">
													<span>{{ scope.row.time | datefmt('HH:mm:ss')}}</span>
												</template>
											</el-table-column>
										</el-table>
									</div>
								</el-col>
								<el-col :sm="12">
									<div class="u7-panel u1-panel" :class="{show:smCurrent == 2}">
										<el-tabs v-model="activeName5" @tab-click="handleClick">
											<el-tab-pane name="all">
												<span slot="label">
													<img src="../../assets/photo/web/block1.png" alt="">
												</span>
												<div class="ui-row clearfix">
													<div class="ui-table">
														<div class="ui-table-head">
															<div class="ui-table-tr">
																<div class="ui-table-th col1">
																	{{$t('wth3')}}(USDT)
																</div>
																<div class="ui-table-th col2">
																	{{$t('wtth4')}}(BTC)
																</div>
																<div class="ui-table-th col3">
																	{{$t('total')}}(USDT)
																</div>
															</div>
														</div>
														<div class="ui-table-body">
															<div class="ui-table-up ui-table-height">
																<div class="ui-table-cell"
																	v-for="(item,index) in plate.askRows" :key="index">
																	<div class="ui-table-tr">
																		<div class="ui-table-td">
																			{{item.price | toFixed(baseCoinScale)}}
																		</div>
																		<div class="ui-table-td">
																			{{item.amount | toFixed(coinScale)}}
																		</div>
																		<div class="ui-table-td">
																			{{item.totalAmount | toFixed(coinScale)}}
																		</div>
																	</div>
																	<div class="ui-table-progress"
																		:style="{width: (item.totalAmount/plate.askTotle).toFixed(4) * 100 + '%'}">
																	</div>
																</div>
															</div>
															<div class="ui-table-subtitle"
																:class="{ buy: currentCoin.change > 0, sell: currentCoin.change < 0 }">
																{{currentCoin.close}}
																<i class="el-icon-top"
																	v-if="currentCoin.change > 0"></i>
																<i class="el-icon-bottom" v-else></i>
															</div>
														</div>
													</div>
													<div class="ui-table ui-table2">
														<div class="ui-table-head">
															<div class="ui-table-tr">
																<div class="ui-table-th col1">
																	{{$t('wth3')}}(USDT)
																</div>
																<div class="ui-table-th col2">
																	{{$t('wtth4')}}(BTC)
																</div>
																<div class="ui-table-th col3">
																	{{$t('total')}}(USDT)
																</div>
															</div>
														</div>
														<div class="ui-table-body">
															<div class="ui-table-down ui-table-height">
																<div class="ui-table-cell"
																	v-for="(item,index) in plate.bidRows" :key="index">
																	<div class="ui-table-tr">
																		<div class="ui-table-td">
																			{{item.price | toFixed(baseCoinScale)}}
																		</div>
																		<div class="ui-table-td">
																			{{item.amount | toFixed(coinScale)}}
																		</div>
																		<div class="ui-table-td">
																			{{item.totalAmount | toFixed(coinScale)}}
																		</div>
																	</div>
																	<div class="ui-table-progress"
																		:style="{width: (item.totalAmount/plate.bidTotle).toFixed(4) * 100 + '%'}">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</el-tab-pane>
											<el-tab-pane name="buy">
												<span slot="label">
													<img src="../../assets/photo/web/block2.png" alt="">
												</span>
												<div class="ui-table ">
													<div class="ui-table-head">
														<div class="ui-table-tr">
															<div class="ui-table-th col1">
																{{$t('wth3')}}(USDT)
															</div>
															<div class="ui-table-th col2">
																{{$t('wtth4')}}(BTC)
															</div>
															<div class="ui-table-th col3">
																{{$t('total')}}(USDT)
															</div>
														</div>
													</div>
													<div class="ui-table-body">
														<div class="ui-table-subtitle"
															:class="{ buy: currentCoin.change > 0, sell: currentCoin.change < 0 }">
															{{currentCoin.close}}
															<i class="el-icon-top" v-if="currentCoin.change > 0"></i>
															<i class="el-icon-bottom" v-else></i>
														</div>
														<div class="ui-table-down">
															<div class="ui-table-cell"
																v-for="(item,index) in plate.bidRows" :key="index">
																<div class="ui-table-tr">
																	<div class="ui-table-td">
																		{{item.price | toFixed(baseCoinScale)}}
																	</div>
																	<div class="ui-table-td">
																		{{item.amount | toFixed(coinScale)}}
																	</div>
																	<div class="ui-table-td">
																		{{item.totalAmount | toFixed(coinScale)}}
																	</div>
																</div>
																<div class="ui-table-progress"
																	:style="{width: (item.totalAmount/plate.bidTotle).toFixed(4) * 100 + '%'}">
																</div>
															</div>
														</div>
													</div>
												</div>
											</el-tab-pane>
											<el-tab-pane name="sell">
												<span slot="label">
													<img src="../../assets/photo/web/block3.png" alt="">
												</span>
												<div class="ui-table ">
													<div class="ui-table-head">
														<div class="ui-table-tr">
															<div class="ui-table-th col1">
																{{$t('wth3')}}(USDT)
															</div>
															<div class="ui-table-th col2">
																{{$t('wtth4')}}(BTC)
															</div>
															<div class="ui-table-th col3">
																{{$t('total')}}(USDT)
															</div>
														</div>
													</div>
													<div class="ui-table-body">
														<div class="ui-table-up">
															<div class="ui-table-cell"
																v-for="(item,index) in plate.askRows" :key="index">
																<div class="ui-table-tr">
																	<div class="ui-table-td">
																		{{item.price | toFixed(baseCoinScale)}}
																	</div>
																	<div class="ui-table-td">
																		{{item.amount | toFixed(coinScale)}}
																	</div>
																	<div class="ui-table-td">
																		{{item.totalAmount | toFixed(coinScale)}}
																	</div>
																</div>
																<div class="ui-table-progress"
																	:style="{width: (item.totalAmount/plate.askTotle).toFixed(4) * 100 + '%'}">
																</div>
															</div>
														</div>
														<div class="ui-table-subtitle"
															:class="{ buy: currentCoin.change > 0, sell: currentCoin.change < 0 }">
															{{currentCoin.close}}
															<i class="el-icon-top" v-if="currentCoin.change > 0"></i>
															<i class="el-icon-bottom" v-else></i>
														</div>
													</div>
												</div>
											</el-tab-pane>
										</el-tabs>
									</div>
								</el-col>
							</el-row>
						</div>
						<!-- 移动端适应 -->
						<div class="u6-panel">
							<div class="u6-tab">
								<el-tabs v-model="activeName3" type="card">
									<el-tab-pane :label="$t('mTab2')" name="first">
										<div class="u6-table">
											<el-table :data="tableData2" style="width: 100%" height="415">
												<el-table-column type="index" label="#" align="center" width="35">
												</el-table-column>
												<el-table-column prop="symbol" :label="$t('wTxt3')" align="center">
												</el-table-column>
												<el-table-column prop="coinSymbol" :label="$t('orth2')" align="center">
												</el-table-column>
												<el-table-column prop="type" :label="$t('mth3')" align="center">
													<template slot-scope="scope">
														<span v-if="scope.row.type == 'NO'"
															lass="text-grey">{{ $t('orderTypeNo') }}</span>
														<span v-else lass="text-grey">{{ $t('orderTypeYes') }}</span>
													</template>
												</el-table-column>
												<el-table-column prop="direction" :label="$t('wth2')" align="center">
													<template slot-scope="scope">
														<span v-if="scope.row.direction == 'BUY'"
															lass="text-grey">{{ $t('qcbtn1') }}</span>
														<span v-else class="text-grey">{{ $t('qcbtn2') }}</span>
													</template>
												</el-table-column>
												<el-table-column prop="openPrice" :label="$t('mth4')" align="center">
													<template slot-scope="scope">
														<span class="text-red">{{ scope.row.openPrice }}</span>
													</template>
												</el-table-column>
												<el-table-column prop="betAmount" :label="$t('wtth4')" align="center">
												</el-table-column>
												<el-table-column prop="cycleLength" :label="$t('mth5')" align="center">
													<template slot-scope="scope">
														<span class="text-red">{{ scope.row.cycleLength }} s</span>
													</template>
												</el-table-column>
												<el-table-column prop="createTime" :label="$t('wth1')" align="center"
													width="180">
													<template slot-scope="scope">
														<span>{{scope.row.createTime | datefmt('YYYY-MM-DD HH:mm')}}</span>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-tab-pane>
									<el-tab-pane :label="$t('mTab3')" name="second">
										<div class="u6-table">
											<el-table :data="tableData3" style="width: 100%" height="415">
												<el-table-column type="index" label="#" align="center" width="35">
												</el-table-column>
												<el-table-column prop="symbol" :label="$t('wTxt3')" align="center">
												</el-table-column>
												<el-table-column prop="coinSymbol" :label="$t('orth2')" align="center">
												</el-table-column>
												<el-table-column prop="type" :label="$t('mth3')" align="center">
													<template slot-scope="scope">
														<span v-if="scope.row.type == 'NO'"
															lass="text-grey">{{ $t('orderTypeNo') }}</span>
														<span v-else lass="text-grey">{{ $t('orderTypeYes') }}</span>
													</template>
												</el-table-column>
												<el-table-column prop="direction" :label="$t('wth2')" align="center">
													<template slot-scope="scope">
														<span v-if="scope.row.direction == 'BUY'"
															lass="text-grey">{{ $t('qcbtn1') }}</span>
														<span v-else class="text-grey">{{ $t('qcbtn2') }}</span>
													</template>
												</el-table-column>
												<el-table-column prop="openPrice" :label="$t('mth4')" align="center">
													<template slot-scope="scope">
														<span class="text-red">{{ scope.row.openPrice }}</span>
													</template>
												</el-table-column>
												<el-table-column prop="betAmount" :label="$t('wtth4')" align="center">
												</el-table-column>
												<el-table-column prop="cycleLength" :label="$t('mth5')" align="center">
													<template slot-scope="scope">
														<span class="text-red">{{ scope.row.cycleLength }} s</span>
													</template>
												</el-table-column>
												<el-table-column prop="closePrice" :label="$t('mth6')" align="center">
												</el-table-column>
												<el-table-column prop="winAmount" :label="$t('mth7')" align="center">
												</el-table-column>
												<el-table-column prop="result" :label="$t('wtth8')" align="center">
													<template slot-scope="scope">
														<span class="text-green"
															v-if="scope.row.result == 'WIN'">{{ $t('win') }}</span>
														<span class="text-grey"
															v-else-if="scope.row.result == 'TIED'">{{ $t('tied') }}</span>
														<span class="text-red"
															v-else-if="scope.row.result == 'LOSE'">{{ $t('lose') }}</span>
														<span v-else>--</span>
													</template>
												</el-table-column>
												<el-table-column prop="createTime" :label="$t('wth1')" align="center"
													width="180">
													<template slot-scope="scope">
														<span>{{scope.row.createTime | datefmt('YYYY-MM-DD HH:mm')}}</span>
													</template>
												</el-table-column>
											</el-table>
										</div>
									</el-tab-pane>
								</el-tabs>
							</div>
						</div>

						<!-- 移动端适应 -->
						<div class="mb-show">
							<div class="u2-panel">
								<div class="u5-content">
									<div class="u2-form">
										<div class="u2-form-cell">
											<div class="u2-form-cell__hd">{{$t('mTxt9')}}</div>
											<div class="u2-form-cell__bd u2-radio">
												<el-radio-group v-model="selectedTradeCoin" @change="selectTradeCoin">
													<el-radio-button :label="item" v-for="item in tradeCoins"
														:key="item">
													</el-radio-button>
												</el-radio-group>
											</div>
										</div>
										<div class="u2-form-cell">
											<div class="u2-form-cell__hd">{{$t('mTxt10')}}</div>
											<div class="u2-form-cell__bd u2-radio">
												<el-radio-group v-model="openTime" @change="selectCycle">
													<el-radio-button :label="item.cycleLength" v-for="item in cycles"
														:key="item.id">
														{{item.cycleLength}}s
													</el-radio-button>
												</el-radio-group>
											</div>
										</div>
										<div class="u2-form-item">
											<div class="u2-form-item__hd">
												{{$t('mTxt11')}}
											</div>
											<div class="u2-form-item__bd flex_bd">
												<el-input v-model="cycleRate" readonly></el-input>
											</div>
											<div class="u2-form-item__ft">
												%
											</div>
										</div>
										<div class="u2-form-item">
											<div class="u2-form-item__hd">
												{{$t('mTxt12')}}
											</div>
											<div class="u2-form-item__bd flex_bd">
												<el-input v-model="balance" readonly></el-input>
											</div>
											<div class="u2-form-item__ft">
												{{selectedTradeCoin}}
											</div>
										</div>
										<div class="u2-form-item u2-form-item--hover">
											<div class="u2-form-item__hd">
												{{$t('mTxt13')}}
											</div>
											<div class="u2-form-item__bd flex_bd">
												<el-input v-model.number="amount"></el-input>
											</div>
											<div class="u2-form-item__ft">
												{{selectedTradeCoin}}
											</div>
										</div>
									</div>
									<div class="u2-foot">
										<el-row :gutter="6">
											<el-col :span="12">
												<el-button type="success" @click="addClickOrder(0)">{{$t('qcbtn1')}}
												</el-button>
											</el-col>
											<el-col :span="12">
												<el-button type="danger" @click="addClickOrder(1)">{{$t('qcbtn2')}}
												</el-button>
											</el-col>
										</el-row>
									</div>
								</div>
								<div class="u2-content-mask" v-show="!isLogin">
									<div class="u2-isLogin">
										{{$t('please')}}
										<router-link to="/login">{{$t('meun8')}}</router-link>/<router-link
											to="/register">
											{{$t('meun9')}}
										</router-link>
									</div>
								</div>
							</div>
							<!-- 移动端适应 -->
						</div>
					</div>
					<div class="ustandard-col5 ustandard-favor">
						<div class="u7-panel u1-panel">
							<el-tabs v-model="activeName5" @tab-click="handleClick">
								<el-tab-pane name="all">
									<span slot="label">
										<img src="../../assets/photo/web/block1.png" alt="">
									</span>
									<div class="ui-table ">
										<div class="ui-table-head">
											<div class="ui-table-tr">
												<div class="ui-table-th col1">
													{{$t('wth3')}}(USDT)
												</div>
												<div class="ui-table-th col2">
													{{$t('wtth4')}}(BTC)
												</div>
												<div class="ui-table-th col3">
													{{$t('total')}}(USDT)
												</div>
											</div>
										</div>
										<div class="ui-table-body">
											<div class="ui-table-up ui-table-height">
												<div class="ui-table-cell" v-for="(item,index) in plate.askRows"
													:key="index">
													<div class="ui-table-tr">
														<div class="ui-table-td">
															{{item.price | toFixed(baseCoinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.amount | toFixed(coinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.totalAmount | toFixed(coinScale)}}
														</div>
													</div>
													<div class="ui-table-progress"
														:style="{width: (item.totalAmount/plate.askTotle).toFixed(4) * 100 + '%'}">
													</div>
												</div>
											</div>
											<div class="ui-table-subtitle"
												:class="{ buy: currentCoin.change > 0, sell: currentCoin.change < 0 }">
												{{currentCoin.close}}
												<i class="el-icon-top" v-if="currentCoin.change > 0"></i>
												<i class="el-icon-bottom" v-else></i>
											</div>
											<div class="ui-table-down ui-table-height">
												<div class="ui-table-cell" v-for="(item,index) in plate.bidRows"
													:key="index">
													<div class="ui-table-tr">
														<div class="ui-table-td">
															{{item.price | toFixed(baseCoinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.amount | toFixed(coinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.totalAmount | toFixed(coinScale)}}
														</div>
													</div>
													<div class="ui-table-progress"
														:style="{width: (item.totalAmount/plate.bidTotle).toFixed(4) * 100 + '%'}">
													</div>
												</div>
											</div>
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane name="buy">
									<span slot="label">
										<img src="../../assets/photo/web/block2.png" alt="">
									</span>
									<div class="ui-table ">
										<div class="ui-table-head">
											<div class="ui-table-tr">
												<div class="ui-table-th col1">
													{{$t('wth3')}}(USDT)
												</div>
												<div class="ui-table-th col2">
													{{$t('wtth4')}}(BTC)
												</div>
												<div class="ui-table-th col3">
													{{$t('total')}}(USDT)
												</div>
											</div>
										</div>
										<div class="ui-table-body">
											<div class="ui-table-subtitle"
												:class="{ buy: currentCoin.change > 0, sell: currentCoin.change < 0 }">
												{{currentCoin.close}}
												<i class="el-icon-top" v-if="currentCoin.change > 0"></i>
												<i class="el-icon-bottom" v-else></i>
											</div>
											<div class="ui-table-down ui-table-height2">
												<div class="ui-table-cell" v-for="(item,index) in plate.bidRows"
													:key="index">
													<div class="ui-table-tr">
														<div class="ui-table-td">
															{{item.price | toFixed(baseCoinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.amount | toFixed(coinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.totalAmount | toFixed(coinScale)}}
														</div>
													</div>
													<div class="ui-table-progress"
														:style="{width: (item.totalAmount/plate.bidTotle).toFixed(4) * 100 + '%'}">
													</div>
												</div>
											</div>
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane name="sell">
									<span slot="label">
										<img src="../../assets/photo/web/block3.png" alt="">
									</span>
									<div class="ui-table ">
										<div class="ui-table-head">
											<div class="ui-table-tr">
												<div class="ui-table-th col1">
													{{$t('wth3')}}(USDT)
												</div>
												<div class="ui-table-th col2">
													{{$t('wtth4')}}(BTC)
												</div>
												<div class="ui-table-th col3">
													{{$t('total')}}(USDT)
												</div>
											</div>
										</div>
										<div class="ui-table-body">
											<div class="ui-table-up ui-table-height2">
												<div class="ui-table-cell" v-for="(item,index) in plate.askRows"
													:key="index">
													<div class="ui-table-tr">
														<div class="ui-table-td">
															{{item.price | toFixed(baseCoinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.amount | toFixed(coinScale)}}
														</div>
														<div class="ui-table-td">
															{{item.totalAmount | toFixed(coinScale)}}
														</div>
													</div>
													<div class="ui-table-progress"
														:style="{width: (item.totalAmount/plate.askTotle).toFixed(4) * 100 + '%'}">
													</div>
												</div>
											</div>
											<div class="ui-table-subtitle"
												:class="{ buy: currentCoin.change > 0, sell: currentCoin.change < 0 }">
												{{currentCoin.close}}
												<i class="el-icon-top" v-if="currentCoin.change > 0"></i>
												<i class="el-icon-bottom" v-else></i>
											</div>
										</div>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
						<div class="u2-panel">
							<div class="u5-content">
								<div class="u2-form">
									<div class="u2-form-cell">
										<div class="u2-form-cell__hd">{{$t('mTxt9')}}</div>
										<div class="u2-form-cell__bd u2-radio">
											<el-radio-group v-model="selectedTradeCoin" @change="selectTradeCoin">
												<el-radio-button :label="item" v-for="item in tradeCoins" :key="item">
												</el-radio-button>
											</el-radio-group>
										</div>
									</div>
									<div class="u2-form-cell">
										<div class="u2-form-cell__hd">{{$t('mTxt10')}}</div>
										<div class="u2-form-cell__bd u2-radio">
											<el-radio-group v-model="openTime" @change="selectCycle">
												<el-radio-button v-for="(item,index) in cycles"
													:label="item.cycleLength" :key="index">
													{{item.cycleLength}}s
												</el-radio-button>
											</el-radio-group>
										</div>
									</div>
									<div class="u2-form-item">
										<div class="u2-form-item__hd">
											{{$t('mTxt11')}}
										</div>
										<div class="u2-form-item__bd flex_bd">
											<el-input v-model="cycleRate" readonly></el-input>
										</div>
										<div class="u2-form-item__ft">
											%
										</div>
									</div>
									<div class="u2-form-item">
										<div class="u2-form-item__hd">
											{{$t('mTxt12')}}
										</div>
										<div class="u2-form-item__bd flex_bd">
											<el-input v-model="balance" readonly></el-input>
										</div>
										<div class="u2-form-item__ft">
											{{selectedTradeCoin}}
										</div>
									</div>
									<div class="u2-form-item u2-form-item--hover">
										<div class="u2-form-item__hd">
											{{$t('mTxt13')}}
										</div>
										<div class="u2-form-item__bd flex_bd">
											<el-input type="number" :placeholder="$t('trTxt')" v-model.number="amount"
												@input="amountChange"></el-input>
										</div>
										<div class="u2-form-item__ft">
											{{selectedTradeCoin}}
										</div>
									</div>
								</div>
								<div class="u2-foot">
									<el-row :gutter="6">
										<el-col :span="12">
											<el-button type="success" @click="addClickOrder(0)">{{$t('qcbtn1')}}
											</el-button>
										</el-col>
										<el-col :span="12">
											<el-button type="danger" @click="addClickOrder(1)">{{$t('qcbtn2')}}
											</el-button>
										</el-col>
									</el-row>
								</div>
							</div>
							<div class="u2-content-mask" v-show="!isLogin">
								<div class="u2-isLogin">
									{{$t('please')}}
									<router-link to="/login">{{$t('meun8')}}</router-link>/<router-link to="/register">
										{{$t('meun9')}}
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<!-- 移动端 -->
					<div class="mb-foot-height">
						<div class="mb-foot">
							<el-row :gutter="15">
								<el-col :span="12">
									<div class="u2-foot">
										<el-button class="el-button--light" @click="drawer = true">
											{{$t('qcbtn1')}}
										</el-button>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="u2-foot">
										<el-button type="danger" @click="drawer = true">
											{{$t('qcbtn2')}}
										</el-button>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
					<el-drawer :visible.sync="drawer" direction="btt" size="70%">
						<div class="u2-panel" style="background-color: unset;">
							<div class="u5-content">
								<div class="u2-form">
									<div class="u2-form-cell">
										<div class="u2-form-cell__hd">{{$t('mTxt9')}}</div>
										<div class="u2-form-cell__bd u2-radio">
											<el-radio-group v-model="selectedTradeCoin" @change="selectTradeCoin">
												<el-radio-button :label="item" v-for="item in tradeCoins" :key="item">
												</el-radio-button>
											</el-radio-group>
										</div>
									</div>
									<div class="u2-form-cell">
										<div class="u2-form-cell__hd">{{$t('mTxt10')}}</div>
										<div class="u2-form-cell__bd u2-radio">
											<el-radio-group v-model="openTime" @change="selectCycle">
												<el-radio-button v-for="(item,index) in cycles"
													:label="item.cycleLength" :key="index">
													{{item.cycleLength}}s
												</el-radio-button>
											</el-radio-group>
										</div>
									</div>
									<div class="u2-form-item">
										<div class="u2-form-item__hd">
											{{$t('mTxt11')}}
										</div>
										<div class="u2-form-item__bd flex_bd">
											<el-input v-model="cycleRate" readonly></el-input>
										</div>
										<div class="u2-form-item__ft">
											%
										</div>
									</div>
									<div class="u2-form-item">
										<div class="u2-form-item__hd">
											{{$t('mTxt12')}}
										</div>
										<div class="u2-form-item__bd flex_bd">
											<el-input v-model="balance" readonly></el-input>
										</div>
										<div class="u2-form-item__ft">
											{{selectedTradeCoin}}
										</div>
									</div>
									<div class="u2-form-item u2-form-item--hover">
										<div class="u2-form-item__hd">
											{{$t('mTxt13')}}
										</div>
										<div class="u2-form-item__bd flex_bd">
											<el-input type="number" :placeholder="$t('trTxt')" v-model.number="amount"
												@input="amountChange"></el-input>
										</div>
										<div class="u2-form-item__ft">
											{{selectedTradeCoin}}
										</div>
									</div>
								</div>
								<div class="u2-foot">
									<el-row :gutter="6">
										<el-col :span="12">
											<el-button type="success" @click="addClickOrder(0)">{{$t('qcbtn1')}}
											</el-button>
										</el-col>
										<el-col :span="12">
											<el-button type="danger" @click="addClickOrder(1)">{{$t('qcbtn2')}}
											</el-button>
										</el-col>
									</el-row>
								</div>
							</div>
							<div class="u2-content-mask" v-show="!isLogin">
								<div class="u2-isLogin">
									{{$t('please')}}
									<router-link to="/login">{{$t('meun8')}}</router-link>/<router-link to="/register">
										{{$t('meun9')}}
									</router-link>
								</div>
							</div>
						</div>
					</el-drawer>
					<!-- 移动端 -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	// TODO:1
	import TV from "@/components/tv";
	import {
		symbolThumb,
		symbolInfo,
		latestTrade,
		plateMini,
		orderCurrent,
		orderHistory,
		orderCoins,
		orderCycles,
		secondBalance,
		orderAdd
	} from '@/api/api/second'
	import {
		favorFind,
		favorAdd,
		favorDelete
	} from '@/api/api/exchange'
	import {
		// exchangeRate,
		plateFull,
		marketCoinInfo
	} from '@/api/api/market'
	import {
		assetWallet
	} from '@/api/api/user'
	import SockJS from 'sockjs-client';
	import Stomp from 'stompjs';
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	export default {
		name: 'Home',
		// TODO:2
		components: {
			Head,
			TV
		},
		data() {
			return {
				drawer: false,
				tableData: [],
				activeName4: 'second',
				activeName3: 'first',
				activeName5: 'all',
				searchVal: '',
				tableData2: [],
				tableData3: [],
				findData: [],
				selectedTradeCoin: 'USDT',
				balance: 0,
				amount: 0,
				radio1: 'Time',
				symbol: '',
				thumb: {
					data: [],
					data1: [],
					data2: [],
					data3: []
				},
				oldData1: [],
				oldData2: [],
				oldData3: [],
				oldData4: [],
				info: {},
				currentCoin: {},
				plate: {
					maxPostion: 10,
					askRows: [],
					bidRows: []
				},
				isLogin: false,
				coinScale: 6,
				baseCoinScale: 6,
				current: 1,
				tradeCoins: [],
				cycles: [],
				selectedCycle: {},
				openTime: '',
				cycleRate: '',

				resolution2: '',
				screenWidth: null,
				smCurrent: 1,
				full: {},
				coinInfo: {},
				popover: false,
				// coin: '$',
				// CNYRate: 1,
				// currentInfo:{}
			};
		},
		computed: {
			keyword: {
				get() {
					return this.searchVal;
				},
				set(val) {
					this.searchVal = val.toUpperCase();
				}
			},
			currentInfo: function() {
				return this.$store.getters.current
			}
		},
		watch: {
			$route() {
				this.init();
			},
			screenWidth: function(n, o) {
				if (n <= 768) {
					console.log(o)
				} else {
					this.smCurrent = 1
				}
			}
		},
		created() {
			this.isLogin = this.$store.state.isLogin

			console.log(this.$store.state.current)
		},
		mounted() {
			this.getInit()
			this.screenWidth = document.body.clientWidth
			window.onresize = () => {
				return (() => {
					this.screenWidth = document.body.clientWidth
				})
			}
		},
		methods: {
			getInit() {
				var str = this.$route.params.id.toUpperCase()
				var arr = str.split('_')
				var symbol = arr[0] + '/' + arr[1]
				this.symbol = symbol
				// this.getCNYRate()
				this.getSymbolScale()
				this.getSymbol()
				this.getTrade()
				this.getPlateFull()
				this.getPlate()
				this.getTradeCoins()
				this.getCycles()

				if (this.isLogin) {
					this.getCurrentOrder()
					this.getHistoryOrder()
					this.getWalletBalance()
				}
			},
			getCoinInfo() {
				marketCoinInfo({
					unit: this.currentCoin.coin,
				}).then(res => {
					var resp = res
					if (resp != null) {
						this.coinInfo = resp
					}
				})
			},
			// getCurrent(item) {
			// 	console.log(item)
			// 	this.CNYRate = item.rate
			// 	this.coin = item.symbol
			// },
			// getCNYRate() {
			// 	exchangeRate().then(res => {
			// 		console.log(res)
			// 		this.CNYRate = res.data
			// 	})
			// },
			getSymbol() {
				symbolThumb().then(res => {
					var arr = []
					res.forEach(item => {
						item.isFavor = false
						item.chg = this.$math.format(
							this.$math.multiply(
								this.$math.bignumber(100), this.$math.bignumber(item.chg)
							)
						)
						return arr.push(item)
					})
					console.log(arr)
					this.thumb.data = arr
					var usdt = arr.filter(item => {
						return item.symbol.split('/')[1] == 'USDT'
					})
					this.thumb.data1 = this.oldData1 = usdt
					var btc = arr.filter(item => {
						return item.symbol.split('/')[1] == 'BTC'
					})
					this.thumb.data2 = this.oldData2 = btc
					var eth = arr.filter(item => {
						return item.symbol.split('/')[1] == 'ETH'
					})
					this.thumb.data3 = this.oldData3 = eth

					var str = this.$route.params.id.toUpperCase()
					var arr1 = str.split('_')
					var symbol = arr1[0] + '/' + arr1[1]
					var coinInfo = this.thumb.data.filter(item => {
						return item.symbol == symbol
					})




					this.currentCoin = coinInfo[0]
					this.currentCoin.rose = coinInfo[0].chg > 0 ? '+' + coinInfo[0].chg + '%' : coinInfo[0].chg +
						'%';
					this.currentCoin.coin = coinInfo[0].symbol.split('/')[0]
					this.currentCoin.base = coinInfo[0].symbol.split('/')[1]


					this.getCoinInfo()
					if (this.isLogin == true) {
						this.getFavor()
					}
					this.startWebsock()
				})
			},
			getSymbolScale() {
				symbolInfo({
					symbol: this.symbol
				}).then(res => {
					this.info = res
					this.baseCoinScale = res.baseCoinScale
					this.coinScale = res.coinScale
				})
			},
			getFavor() {
				favorFind().then(res => {
					this.findData = []
					this.oldData4 = []
					this.thumb.data.forEach(item => {
						res.forEach(itx => {
							if (item.symbol == itx.symbol) {
								item.isFavor = true
								this.findData.push(item)
								this.oldData4.push(item)
							}
						})
					})
				})
			},
			getTradeCoins() {
				orderCoins().then(res => {
					if (res.code == 0) {
						this.tradeCoins = res.data
					} else {
						this.$notify.error({
							title: this.$t('apiTxt2'),
							message: res.message,
						})
					}
				})
			},
			getWalletBalance() {
				secondBalance({
					symbol: this.selectedTradeCoin
				}).then(res => {
					this.balance = res.data
				})
			},
			selectTradeCoin(value) {
				this.selectedTradeCoin = value
				this.getWalletBalance()
			},
			selectCycle(value) {
				const arr = this.cycles.filter(item => {
					return item.cycleLength == value
				})
				this.cycleRate = arr[0].cycleRate * 100
			},
			getCycles() {
				orderCycles().then(res => {
					if (res.code == 0) {
						this.cycles = res.data
						this.selectedCycle = res.data[0]
						this.openTime = this.selectedCycle.cycleLength
						this.cycleRate = this.selectedCycle.cycleRate * 100
					} else {
						this.$notify.error({
							title: this.$t('apiTxt2'),
							message: res.message,
						})
					}
				})
			},
			getTrade() {
				latestTrade({
					symbol: this.symbol,
					size: 20
				}).then(res => {
					this.tableData = res.slice(0, 20)
				})
			},
			getPlate(str = "") {
				plateMini({
					symbol: this.symbol
				}).then(res => {
					this.plate.askRows = []
					this.plate.bidRows = []
					let data = res
					if (data.ask && data.ask.items) {
						for (let i = 0; i < data.ask.items.length; i++) {
							if (i == 0) {
								data.ask.items[i].totalAmount = data.ask.items[i].amount
							} else {
								data.ask.items[i].totalAmount = data.ask.items[i - 1].totalAmount + data.ask.items[
									i].amount
							}
						}
						if (data.ask.items.length >= this.plate.maxPostion) {
							for (let i = this.plate.maxPostion; i > 0; i--) {
								let ask = data.ask.items[i - 1]
								ask.direction = 'SELL'
								ask.position = i
								this.plate.askRows.push(ask)
								let rows = this.plate.askRows,
									totle = rows[0].totalAmount
								this.plate.askTotle = totle
							}
						} else {
							for (let i = this.plate.maxPostion; i > data.ask.items.length; i--) {
								let ask = {
									price: 0,
									amount: 0
								}
								ask.direction = 'SELL'
								ask.position = i
								ask.totalAmount = ask.amount
								this.plate.askRows.push(ask)

							}
							for (let i = data.ask.items.length; i > 0; i--) {
								let ask = data.ask.items[i - 1]
								ask.direction = 'SELL'
								ask.position = i
								this.plate.askRows.push(ask)
								let askItemIndex = data.ask.items.length - 1 > 0 ? data.ask.items.length - 1 : 0
								let rows = this.plate.askRows,
									totle = rows[askItemIndex].totalAmount
								this.plate.askTotle = totle
							}
						}
					}
					if (data.bid && data.bid.items) {
						for (let i = 0; i < data.bid.items.length; i++) {
							if (i == 0) data.bid.items[i].totalAmount = data.bid.items[i].amount
							else data.bid.items[i].totalAmount = data.bid.items[i - 1].totalAmount + data.bid
								.items[i].amount
						}
						for (let i = 0; i < data.bid.items.length; i++) {
							let bid = data.bid.items[i]
							bid.direction = 'BUY'
							bid.position = i + 1
							this.plate.bidRows.push(bid)
							if (i == this.plate.maxPostion - 1) break
						}
						if (data.bid.items.length < this.plate.maxPostion) {
							for (let i = data.bid.items.length; i < this.plate.maxPostion; i++) {
								let bid = {
									price: 0,
									amount: 0
								}
								bid.direction = 'BUY'
								bid.position = i + 1
								bid.totalAmount = 0
								this.plate.bidRows.push(bid)
							}
							let bidItemIndex = data.bid.items.length - 1 > 0 ? data.bid.items.length - 1 : 0
							let rows = this.plate.bidRows,
								totle = rows[bidItemIndex].totalAmount
							this.plate.bidTotle = totle
						} else {
							let rows = this.plate.bidRows,
								len = rows.length,
								totle = rows[len - 1].totalAmount
							this.plate.bidTotle = totle
						}
					}
					if (str != "") {
						this.activeName5 = str;
					}
				})
			},
			getPlateFull() {
				plateFull({
					symbol: this.symbol
				}).then(res => {
					this.full = res
					if (this.current == 2) {
						var arr1 = []
						var arr2 = []
						var total = 0
						var total2 = 0
						res.ask.items.forEach(item => {
							total += item.amount
							let obj = {
								value: item.price,
								total: total
							}
							arr1.push(obj)
						})
						res.bid.items.forEach(item => {
							total2 += item.amount
							let obj = {
								value: item.price,
								total: total2
							}
							arr2.push(obj)
						})
						let max = Math.max.apply(Math, arr1);
						let min = Math.min.apply(Math, arr1);
						let max2 = Math.max.apply(Math, arr2);
						let min2 = Math.min.apply(Math, arr2);
						this.linechart(arr2, max2, min2);
						this.linechart2(arr1, max, min);
					}
				})
			},
			linechart(arr, max, min) {
				var label2 = this.$t('deptotal')
				var label = this.$t('uTxt11')
				var myChart = echarts.init(document.getElementById('depth'))
				var options = {
					tooltip: {
						trigger: 'axis',
						formatter: function(params) {
							return label + ' ' + params[0].data.value + '<br />' + label2 + ' ' +
								params[0].data.total
						},
						axisPointer: {
							type: 'none'
						}
					},
					grid: {
						left: '0%',
						right: '0%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						axisTick: {
							show: false
						},
						axisLine: {
							lineStyle: {
								color: '#ffffff',
							}
						},
						axisLabel: {
							show: false
						},
						show: false
					}],
					yAxis: [{
						type: 'value',
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							inside: true,
							verticalAlign: 'bottom',
						},
						show: false,
						max: max,
						min: min
					}],
					series: [{
						name: 'SELL',
						type: 'line',
						// smooth: true,
						showSymbol: false,
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.2)',
							width: 0
						},
						areaStyle: {
							color: 'rgba(77, 184, 114, 0.4)',
						},
						symbol: 'none',
						label: {
							show: false
						},
						data: arr
					}]
				};
				myChart.setOption(options)
				window.addEventListener('resize', () => {
					myChart.resize()
				})
			},
			linechart2(arr, max, min) {
				var label2 = this.$t('deptotal')
				var label = this.$t('uTxt11')
				var myChart2 = echarts.init(document.getElementById('depth2'))
				var options2 = {
					tooltip: {
						trigger: 'axis',
						formatter: function(params) {
							return label + ' ' + params[0].data.value + '<br />' + label2 + ' ' +
								params[0].data.total
						},
						axisPointer: {
							type: 'none'
						}
					},
					grid: {
						left: '0%',
						right: '0%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						boundaryGap: false,
						axisTick: {
							show: false
						},
						axisLine: {
							lineStyle: {
								color: '#ffffff',
							}
						},
						axisLabel: {
							show: false
						},
						show: false
					}],
					yAxis: [{
						type: 'value',
						splitLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						axisLabel: {
							inside: true,
							verticalAlign: 'bottom',
						},
						show: false,
						max: max,
						min: min
					}],
					series: [{
						name: 'buy',
						type: 'line',
						// smooth: true,
						showSymbol: false,
						areaStyle: {
							color: 'rgba(246, 70, 93, 0.3)',
						},
						lineStyle: {
							color: 'rgba(255, 255, 255, 0.2)',
							width: 0
						},
						symbol: 'none',
						label: {
							show: false
						},
						data: arr
					}]
				};
				myChart2.setOption(options2)
				window.addEventListener('resize', () => {
					myChart2.resize()
				})
			},
			handleClick(tab) {
				var str = tab.name
				if (str == 'all') {
					this.plate.maxPostion = 10
				} else {
					this.plate.maxPostion = 20
				}
				this.getPlate(str)
			},
			getWallet() {
				assetWallet({
					symbol: this.currentCoin.base
				}).then(res => {
					this.wallet.base = res.data.balance
				})
				assetWallet({
					symbol: this.currentCoin.coin
				}).then(res => {
					this.wallet.coin = res.data.balance
				})
			},
			getCurrentOrder() {
				orderCurrent({
					symbol: this.symbol,
					pageNo: 1,
					pageSize: 10
				}).then(res => {
					if (res.code == 0) {
						this.tableData2 = res.data
					}
				})
			},
			getHistoryOrder() {
				orderHistory({
					symbol: this.symbol,
					pageNo: 0,
					pageSize: 10
				}).then(res => {
					this.tableData3 = res.content
				})
			},
			search(value) {
				let keyWord = value.toUpperCase()
				if (this.activeName4 == 'first') {
					if (value == '') {
						this.findData = this.oldData4
					} else {
						this.findData = this.oldData4.filter(item => item.symbol.indexOf(keyWord) >= 0)
					}
				} else if (this.activeName4 == 'second') {
					if (value == '') {
						this.thumb.data1 = this.thumb.oldData1
					} else {
						this.thumb.data1 = this.thumb.oldData1.filter(item => item.symbol.indexOf(keyWord) >= 0)
					}
				} else if (this.activeName4 == 'third') {
					if (value == '') {
						this.thumb.data2 = this.thumb.oldData2
					} else {
						this.thumb.data2 = this.thumb.oldData2.filter(item => item.symbol.indexOf(keyWord) >= 0)
					}
				} else {
					if (value == '') {
						this.thumb.data3 = this.thumb.oldData3
					} else {
						this.thumb.data3 = this.thumb.oldData3.filter(item => item.symbol.indexOf(keyWord) >= 0)
					}
				}
			},
			rowClick(row) {
				var str = row.symbol.toLowerCase()
				var arr = str.split('/')
				var unit = arr[0] + '_' + arr[1]
				this.$router.push({
					params: {
						id: unit
					}
				})
				this.popover = false
				this.getInit()
			},
			addClickOrder(direction) {
				if (this.amount == 0) {
					this.$message.error(this.$t('buyamounttip'))
					return
				}
				if (this.amount > this.balance) {
					this.amount = this.balance
				}

				let params = {}
				params.symbol = this.currentCoin.symbol
				params.direction = direction
				params.coinSymbol = this.selectedTradeCoin
				params.cycleId = this.selectedCycle.id
				params.amount = this.amount
				orderAdd(params).then(res => {
					if (res.code == 0) {
						this.getCurrentOrder() //当前委托
						this.getHistoryOrder() //历史委托
						this.$message.success(this.$t('betsuccess'))
						this.drawer = false
					} else {
						this.$notify.error({
							title: this.$t('apiTxt2'),
							message: res.message,
						})
					}
				})

			},
			refreshAccount: function() {
				this.getCurrentOrder()
				this.getHistoryOrder()
				this.getWallet()
			},
			successCallback(that, stompClient) {
				// TODO:5
				that.$refs.tv.getKline(
					{
						a:that.host + "/second",
						b:that.currentCoin,
						c:stompClient,
						d:that.baseCoinScale
					}
				);
				stompClient.subscribe("/topic/second/thumb", function(msg) {
					var resp = JSON.parse(msg.body);
					if (that.currentCoin != null && that.currentCoin.symbol == resp.symbol) {
						that.currentCoin.close = resp.close;
						that.currentCoin.rose =
							resp.chg > 0 ?
							"+" + (resp.chg * 100).toFixed(2) + "%" :
							(resp.chg * 100).toFixed(2) + "%";
						that.currentCoin.close = resp.close;
						that.currentCoin.high = resp.high;
						that.currentCoin.low = resp.low;
						that.currentCoin.turnover = parseInt(resp.volume);
						that.currentCoin.volume = resp.volume;
						that.currentCoin.usdRate = resp.usdRate;
					}
				});


				stompClient.subscribe('/topic/second/trade/' + that.currentCoin.symbol, function(msg) {
					var resp = JSON.parse(msg.body)
					if (resp.length > 0) {
						for (var i = 0; i < resp.length; i++) {
							that.tableData.unshift(resp[i])
						}
					}
					if (that.tableData.length > 20) {
						that.tableData = that.tableData.slice(0, 20)
					}
				})


				if (that.isLogin) {
					//订阅委托取消信息
					// stompClient.subscribe(
					// 	'/topic/second/order-canceled/' + that.currentCoin.symbol + '/' + that.member.id,
					// 	function(msg) {
					// 		var resp = JSON.parse(msg.body)
					// 		that.refreshAccount()
					// 	}
					// )
					// //订阅委托交易完成
					// stompClient.subscribe(
					// 	'/topic/second/order-completed/' + that.currentCoin.symbol + '/' + that.member.id,
					// 	function(msg) {
					// 		var resp = JSON.parse(msg.body)
					// 		that.refreshAccount()
					// 	}
					// )
					// //订阅委托部分交易
					// stompClient.subscribe('/topic/second/order-trade/' + that.currentCoin.symbol + '/' + that
					// 	.member.id,
					// 	function(
					// 		msg
					// 	) {
					// 		var resp = JSON.parse(msg.body)
					// 		that.refreshAccount()
					// 	})
				}


				stompClient.subscribe('/topic/second/trade-plate/' + that.currentCoin.symbol, function(
					msg) {
					var resp = JSON.parse(msg.body)
					if (resp.direction == 'SELL') {
						var asks = resp.items
						that.plate.askRows = []
						let totle = 0
						for (let i = that.plate.maxPostion - 1; i >= 0; i--) {
							var ask = {}
							if (i < asks.length) {
								ask = asks[i]
							} else {
								ask['price'] = 0
								ask['amount'] = 0
							}
							ask.direction = 'SELL'
							ask.position = i + 1
							that.plate.askRows.push(ask)
						}
						for (let i = that.plate.askRows.length - 1; i >= 0; i--) {
							if (i == that.plate.askRows.length - 1 || that.plate.askRows[i]
								.price == 0) {
								that.plate.askRows[i].totalAmount = that.plate.askRows[i].amount
							} else {
								that.plate.askRows[i].totalAmount = that.plate.askRows[i + 1]
									.totalAmount + that.plate.askRows[i].amount
							}
							totle += that.plate.askRows[i].amount
						}
						that.plate.askTotle = totle
					} else {
						var bids = resp.items
						that.plate.bidRows = []
						let totle = 0
						for (let i = 0; i < that.plate.maxPostion; i++) {
							var bid = {}
							if (i < bids.length) {
								bid = bids[i]
							} else {
								bid['price'] = 0
								bid['amount'] = 0
							}
							bid.direction = 'BUY'
							bid.position = i + 1
							that.plate.bidRows.push(bid)
						}
						for (let i = 0; i < that.plate.bidRows.length; i++) {
							if (i == 0 || that.plate.bidRows[i].amount == 0) {
								that.plate.bidRows[i].totalAmount = that.plate.bidRows[i].amount
							} else {
								that.plate.bidRows[i].totalAmount = that.plate.bidRows[i - 1]
									.totalAmount + that.plate.bidRows[i].amount
							}
							totle += that.plate.bidRows[i].amount
						}
						that.plate.bidTotle = totle
					}

					if (that.current == 2) {
						that.getPlateFull()
					}
				})
			},
			startWebsock() {
				if (this.stompClient) {
					this.stompClient.ws.close()
				}
				var stompClient = null
				var that = this
				var socket = new SockJS(this.host + '/second/second-ws')
				stompClient = Stomp.over(socket)
				this.stompClient = stompClient
				stompClient.debug = false

				stompClient.connect({}, function(frame) {
					console.log(frame);
					that.successCallback(that, stompClient);
				}, () => {
					// if (!this.stompClient) {
						console.log("not open....");
						that.reconnect(that)
					// }
				});
        socket.onclose = () => {
          console.log('连接已断开1');
          that.reconnect(that)
        };
        socket.onerror = (error) => {
          console.error('发生错误1: ', error);
          that.reconnect(that)
        };
			},
			reconnect(that) {
				console.info('in reconnect function')
				let connected = false
				const reconInv = setInterval(() => {
					console.info('in interval' + Math.random());
					if (that.stompClient) {
						console.log("reconnect opening.....")
						clearInterval(reconInv)
						connected = true;
						// that.stompClient.ws.close();
					}
					var stompClient = null;
					var socket = new SockJS(this.host + '/second/second-ws')
					stompClient = Stomp.over(socket)
					that.stompClient = stompClient;
					stompClient.debug = false;
					stompClient.connect({}, (frame) => {
						console.info('reconnected success')
						// 连接成功，清除定时器
						console.log(frame);
						clearInterval(reconInv)
						connected = true
						that.successCallback(that, stompClient)
					}, () => {
						console.info('reconnect failed')
						console.info('connected:' + connected)
						if (connected) {
							console.info('connect .... what?')
						}
						that.reconnect(that);
					});
				}, 2000)
			},
			keyup(e) {
				e.target.value = e.target.value.replace(/[^\d]/g, "")
			},
			currentClick(index) {
				this.current = index
				if (index == 2) {
					this.getPlateFull()
				}
			},
			addFavor(row) {
				if (this.isLogin == false) {
					this.$message.info(this.$t('loginFirst'))
				} else {
					favorAdd({
						symbol: row.symbol
					}).then(res => {
						if (res.code == 0) {
							this.$message.info(this.$t('do_favorite'))
							row.isFavor = true
							this.findData.push(row)
						} else {
							this.$message.error(res.message)
						}
					})
				}
			},
			delFavor(row, index) {
				favorDelete({
					symbol: row.symbol
				}).then(res => {
					if (res.code == 0) {
						this.$message.info(this.$t('cancel_favorite'))
						if (this.activeName4 == 'first') {
							this.findData.splice(index, 1)
						} else {
							row.isFavor = false
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			amountChange(value) {
				if (value != '') {
					if (value.indexOf('.') > -1) {
						this.amount = value.slice(0, value.indexOf('.') + 3)
					} else {
						this.amount = value
					}
				}
			}
		}
	}
</script>
